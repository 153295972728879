@import 'modules/_clearfix.scss';
@import 'modules/_normalize.scss';

body,html {
  font-family: "messina_sans", sans-serif;
  color: rgba(0,0,0,0.6);
}

* {
  box-sizing: border-box;
}

body {
  background: #E4E4E4;
  margin: 80px;
}

h1 {
  font-weight: normal;
}

body {
  line-height: 1.5;
  display:flex;
//  justify-content: center;
  //align-items: center;
//  min-height: calc(100vh - 60px);
}

main {
//  width: 80%;
  transition: all 2s;
  opacity: 0;
  transform: translateY(100px);
  filter: blur(50%);
}

.suss main {
  opacity: 1;
  filter: none;
  transform: none;
}

h1 {
  margin: 0;
  margin-bottom: 66px;
  line-height: 1.2;
//  border: 1px pink solid;
}

p {
  font-size: 13px;
  a {
    color: rgba(0,0,0,0.66);
    &:hover {
      text-decoration: none;
    }
  }
}

h1 {
  font-size: 39px;
  color: #FFF;
  line-height: 1
}

main {
}

#circle {
  position:absolute;
  top:80px;
  left: 80px;
  background: white;
}
